<template>
  <Sidebar
    v-model:visible="isCartSidebarVisible"
    :blockScroll="true"
    :header="'Plano de Mídia'"
    class="cart-sidebar fixed right-0 top-0 z-[1000] h-screen w-full overflow-y-auto bg-white lg:w-1/2"
    style="height: -webkit-fill-available"
    :position="'right'"
    @hide="emit('close-cart-sidebar')"
  >
    <div class="h-full w-full overflow-hidden">
      <div class="mx-auto flex h-full !w-full flex-col items-center 2xl:max-w-8xl">
        <div class="lg:px- h-full w-full">
          <template v-if="!pointList.length && loading === undefined ? true : loading">
            <CartSkeleton />
          </template>
          <template v-else>
            <template v-if="cartCount === 0">
              <div
                class="flex h-full w-full flex-1 flex-col items-center justify-center gap-20 self-stretch pb-[1px] pl-[48px] pr-[108px] pt-[32px]"
              >
                <div class="flex flex-col items-center gap-[38px] self-stretch">
                  <p class="text-center">
                    <strong class="mx-auto text-4xl">Seu plano de mídia está vazio</strong>
                    <br />
                    <Button class="mt-5" label="começar um plano de mídia" @click="createAd()" />
                  </p>
                </div>
                <div v-if="lastCampaign.name && lastCampaign.id" class="flex flex-col items-center gap-4 self-stretch">
                  <p class="text-center text-[17.5px] font-semibold leading-[26.3px] text-[var(--surface-700,#616161)]">
                    o seu último trabalho está salvo em meus anúncios
                  </p>
                  <Card
                    class="flex cursor-pointer flex-col items-start gap-10 rounded-lg border border-[var(--Neutral-Low-Dark,#1F262E)] bg-[var(--Neutral-010,#FFF)] p-7 transition-all duration-200 hover:border-[var(--surface-700,#616161)] hover:shadow-lg"
                    @click="handleCardClick(lastCampaign.order_id)"
                  >
                    <template #content>
                      <div class="flex items-start gap-8">
                        <div class="flex flex-col items-start gap-[14px] self-stretch">
                          <div class="flex flex-wrap content-center items-center gap-2">
                            <h2 class="flex flex-wrap content-center items-center gap-x-[12px] gap-y-[2px]">
                              <span
                                class="line-clamp-1 overflow-hidden text-ellipsis text-[21px] font-bold leading-[31.5px] text-[var(--Neutral-Low-Dark,#1F262E)]"
                                >{{ lastCampaign.name }}</span
                              >
                              <span
                                class="line-clamp-1 overflow-hidden text-ellipsis text-[16px] font-medium leading-[22.4px] tracking-[0.056em] text-[var(--Neutral-High-Medium,#B0B8C2)]"
                                >#{{ idOrder }}</span
                              >
                            </h2>
                          </div>
                          <div class="flex flex-col items-start gap-4">
                            <div class="flex items-center gap-6">
                              <p class="flex items-center gap-1">
                                <span
                                  class="text-[14px] font-medium leading-[21px] text-[var(--Neutral-Low-Dark,#1F262E)]"
                                  >Início:</span
                                >
                                <span
                                  class="text-[14px] font-medium leading-[21px] text-[var(--Neutral-High-Dark,#8C97A6)]"
                                  >{{ getDate(lastCampaign).start }}</span
                                >
                              </p>
                              <p class="flex items-center gap-1">
                                <span
                                  class="text-[14px] font-medium leading-[21px] text-[var(--Neutral-Low-Dark,#1F262E)]"
                                  >Fim:</span
                                >
                                <span
                                  class="text-[14px] font-medium leading-[21px] text-[var(--Neutral-High-Dark,#8C97A6)]"
                                  >{{ getDate(lastCampaign).end }}</span
                                >
                              </p>
                              <p class="flex items-center gap-1">
                                <span
                                  class="text-[14px] font-medium leading-[21px] text-[var(--Neutral-Low-Dark,#1F262E)]"
                                  >Prazo:</span
                                >
                                <span
                                  class="text-[14px] font-medium leading-[21px] text-[var(--Neutral-High-Dark,#8C97A6)]"
                                  >{{ lastCampaign.duration ? lastCampaign.duration + " dias" : "N/A" }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </Card>
                  <Button
                    class="flex items-center gap-2.5 rounded-[6px] border border-[var(--surface-300,#E0E0E0)] !bg-white p-[10.5px_17.5px] !text-black"
                    label="ver meus anúncios"
                    @click="myOrders"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <!-- <div class="mt-12 flex items-center justify-between lg:pl-[48px] lg:pr-[116px]">
                <div class="flex items-center">
                  <p class="mr-2 text-[2.67rem] font-bold">Plano de Mídia</p>
                  <CartBadge :cart-items-count="cartCount" class="mt-1" />
                </div>
                <div
                  class="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-neutral-light1 hover:bg-body"
                  @click="emit('close-cart-sidebar')"
                >
                  <i class="uil uil-times mt-1" style="font-size: 2.2rem !important; color: #1f262e !important"></i>
                  <i class="uil uil-times-circle mt-1 text-black" style="font-size: 2.5rem !important"></i>
                </div>
              </div> -->
              <div class="cart-sidebar h-full min-h-screen overflow-y-auto scroll-smooth !pb-[286px]">
                <div class="mt-4 flex w-full gap-5 lg:px-[48px]">
                  <MetricCard
                    small
                    :number="items_summary.total_spaces"
                    text="espaços"
                    tooltip="Cada um dos ambientes (Edifícios, Ruas, Transporte)"
                  />
                  <MetricCard
                    small
                    :number="items_summary.total_screens"
                    text="telas"
                    tooltip="Quantidade de telas onde são exibidos os anúncios"
                  />
                  <MetricCard
                    :number="formatNumber(items_summary.total_insertions).reach"
                    :text="isMobile ? 'exibições' : 'exibições do anúncio'"
                    tooltip="Soma total de exibições nos ambientes selecionados"
                  />
                </div>
                <div
                  class="mt-4 flex w-full flex-wrap justify-between lg:mb-[32px] lg:mt-[38px] lg:pl-[48px] lg:pr-[116px]"
                >
                  <div class="flex">
                    <p class="!text-[1.18rem]">
                      <span class="pr-2 !text-neutral-gray6">Início</span>
                      <span class="pr-5 !font-bold !text-neutral-low-dark">{{ campaignDates.start }}</span>
                    </p>
                    <p class="!text-[1.18rem]">
                      <span class="pr-2 !text-neutral-gray6">Fim</span>
                      <span class="pr-5 !font-bold !text-neutral-low-dark">{{ campaignDates.end }}</span>
                    </p>
                  </div>
                  <p class="!text-[1.18rem]">
                    <span class="pr-2 !text-neutral-gray6">Duração total</span>
                    <span class="!font-bold !text-neutral-low-dark">{{ CampaignDuration[duration]?.name }}</span>
                  </p>
                </div>
                <!-- <template v-if="hasStaticCartItems">
                  <div
                    class="mt-2 flex w-full items-center justify-center bg-status-pending-idle px-16 py-4 text-neutral-low-dark lg:mt-0 lg:py-[20px] lg:text-xl"
                  >
                    <i class="uil uil-info-circle mr-6" style="font-size: 2rem !important"></i>
                    <p>
                      <span class="font-bold">Atenção:</span> As mídias impressas possuem prazo de envio de arte e data
                      de instalação diferenciadas.
                    </p>
                  </div>
                </template> -->
                <div class="flex w-full flex-col lg:px-0">
                  <div class="w-full">
                    <div class="flex h-full w-full justify-center lg:mt-0">
                      <template v-if="cartItems.length">
                        <div class="flex w-full flex-col">
                          <div
                            class="flex w-full !items-center justify-between pb-2 lg:pl-[48px] lg:pr-[116px] lg:pt-8"
                          >
                            <p class="text-lg">
                              <span class="text-[1.4rem] font-semibold">{{ cartCount }}</span> ponto{{
                                cartCount !== 1 ? "s" : ""
                              }}
                            </p>
                            <div>
                              <Button
                                v-if="cartItems.length > 0"
                                outlined
                                class="without-border !bg-white !text-neutral-gray7"
                                @click="handleCleanCart"
                              >
                                <i class="uil uil-trash mr-2"></i>
                                <span class="text-lg font-bold">limpar plano de mídia</span>
                              </Button>
                            </div>
                          </div>
                          <div class="min-w-[300px]">
                            <template v-for="(point, index) in cartItems" :key="index">
                              <CartItem
                                :name="point.properties.name"
                                :address="`${point.properties.address}, ${point.properties.number}, ${point.properties.region} - ${point.properties.city}/${point.properties.state}`"
                                :photo="point.properties.photo"
                                :midia="point.properties.midia"
                                :exhibition_type="point.properties.exhibition_type"
                                :price="point.total_price"
                                :originalPrice="point.properties.original_price"
                                :period_insertions="
                                  point.properties.period_insertions * point.properties.screens_max_quantity
                                "
                                :maxQuantity="point.properties.screens_max_quantity"
                                :quantity="point.quantity"
                                :days="duration"
                                :allow_multiples_screens="point.properties.allow_multiples_screens"
                                @remove-from-cart="handleRemoveItem(point.id)"
                                @update-quantity="(quantity) => dispatchAddToCart({ ...point, quantity: quantity })"
                              />
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <template v-if="pointList.length && !loading">
                  <section
                    class="mb-1 w-full border-t-[1px] border-neutral-light8 pt-[32px] lg:pl-[48px] lg:pr-[116px]"
                  >
                    <div>
                      <h2 class="text-3xl font-bold">Resumo do pedido</h2>
                      <div v-auto-animate class="mt-5 w-full border-b-[1px] border-neutral-light8">
                        <template v-if="CartStore.coupon[0]?.is_valid && discounts.data.cart != 0">
                          <div class="mb-[8px] mt-3 flex w-full justify-between text-lg">
                            <p>Subtotal</p>
                            <p>{{ $currency(cartSubtotal) }}</p>
                          </div>
                        </template>

                        <Coupon
                          :progressiveDiscountData="progressiveDiscountData"
                          :showCardProgressiveDiscount="showCardProgressiveDiscount"
                          :hideBuzzleadCoupon="hideBuzzleadCoupon"
                          @progressive-discount="handleProgressiveDiscount()"
                        />
                      </div>
                      <div class="mt-[8px] flex w-full justify-between text-2xl font-bold">
                        <p>Total</p>
                        <p>{{ $currency(Math.max(0, cartTotal)) }}</p>
                      </div>
                      <!-- TODO: separar -->
                      <div v-auto-animate>
                        <div
                          v-if="showCardProgressiveDiscount"
                          class="mr-auto mt-4 flex w-max rounded border border-neutral-light8 p-3"
                        >
                          <div class="flex items-center">
                            <i class="uil-tag-alt m-2 flex h-[24px] w-[24px] items-center text-5xl text-[#E0E0E0]"></i>
                          </div>
                          <div class="flex flex-col">
                            <div class="mb-2 flex">
                              <div class="my-auto mr-5">
                                <strong
                                  class="text-lg font-extrabold leading-6 tracking-[0.015em] text-neutral-low-dark"
                                >
                                  Desconto {{ progressiveDiscountData["campaignName"] }}
                                </strong>
                                <p class="text-neutral-gray6">
                                  {{ progressiveDiscountData["coupon"]["percent"] }}% de desconto
                                </p>
                              </div>
                              <Button
                                :loading="loadingProgressiveDiscount"
                                label="aplicar"
                                @click="handleApplyProgressiveDiscount(progressiveDiscountData['coupon']['code'])"
                              />
                            </div>
                            <p class="text-md text-neutral-gray6">
                              Aplicado mediante critério da campanha
                              <i
                                appent-to="self"
                                class="uil-info-circle cursor-pointer text-base text-neutral-low-dark"
                              ></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </template>
              </div>
              <div
                class="absolute bottom-0 flex !h-auto w-full flex-col justify-end bg-white px-8 pt-4 lg:pl-[48px] lg:pr-[116px] lg:pt-0"
              >
                <div class="flex">
                  <div class="absolute left-0 w-full border-t-[1px] border-neutral-light8"></div>
                </div>
                <div class="w-full items-center py-6">
                  <div class="flex w-full items-center">
                    <Button
                      class="without-border ml-auto mr-2 !text-lg font-bold !text-neutral-gray7"
                      label="fechar"
                      severity="secondary"
                      outlined
                      @click="emit('close-cart-sidebar')"
                    />
                    <Button class="mr-2 !text-lg !font-bold" @click="redirectToCart()">
                      continuar <i class="uil uil-angle-right ml-2 mt-[1px]"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <DialogUploadForm :campaign="campaignId" :showModal="showDialogUpload" @close-modal="handleCloseModal" />
    </div>
  </Sidebar>
</template>

<script setup>
import "jspdf-autotable";

import CartItem from "@components/CartItem.vue";
import Coupon from "@components/Coupon/Coupon.vue";
import MetricCard from "@components/MetricCard.vue";
import { CampaignDuration } from "@enums/OrderEnum";
import Cookies from "js-cookie";
import moment from "moment";
import { storeToRefs } from "pinia";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { computed, inject, onMounted, reactive, ref, watch } from "vue";

import CartSkeleton from "@/components/Cart/components/CartSkeleton.vue";
import DialogUploadForm from "@/components/pages/Campaigns/components/DialogUploadForm.vue";
import { AssetEnum } from "@/enums/AssetEnum";
import { useDeeplink } from "@/hooks";
import useIsMobile from "@/hooks/useIsMobile";
import { rotaAnuncios, rotaPlanoDeMidia, rotaResultados } from "@/routes";
import { getOrdersLimit } from "@/services/order-limit";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useCartStore } from "@/stores/CartStore";
import { useUserStore } from "@/stores/UserStore";
import { trackButton } from "@/utils/analitycsHelper/button";
import { getCouponBuzzlead } from "@/utils/envHelper";
import formatNumber from "@/utils/formatNumber";
import Tracker from "@/utils/Tracker/Tracker";

const props = defineProps({
  isCartSidebarVisible: {
    type: Boolean,
    default: false,
  },
});

const isCartSidebarVisible = ref(props.isCartSidebarVisible);

const whitelabel = Cookies.get("__wl");

const pointList = ref([]);
const campaignId = ref(null);
const cartTotal = ref(0);
const cartSubtotal = ref(0);
const showDialogUpload = ref(false);
const disableNextButton = ref(true);
const hideBuzzleadCoupon = ref(false);

const deeplink = useDeeplink();

const coupon = reactive({ data: "" });
const discounts = reactive({ data: "" });
const campaignDates = reactive({
  start: "",
  end: "",
});
const lastCampaign = ref({});
const idOrder = ref("");

const progressiveDiscountData = ref([]);
const showCardProgressiveDiscount = ref(false);
const loadingProgressiveDiscount = ref(false);

const dataLayer = inject("datalayer");

const couponByPromotionRoute = JSON.parse(localStorage.getItem("coupon"));

const CartStore = useCartStore();
const CampaignStore = useCampaignStore();
const UserStore = useUserStore();

const { loading, cartCount, cartItems, subtotal, total, progressiveDiscount } = storeToRefs(CartStore);
const { items_summary, id, date_start, duration } = storeToRefs(CampaignStore);

const emit = defineEmits(["update:pontos", "updateCart", "close-cart-sidebar"]);

const showCouponInput = ref(false);
const couponForm = reactive({
  coupon: "",
  valid: false,
});

const toast = useToast();
const confirm = useConfirm();
const { isMobile } = useIsMobile();

const couponBuzzlead = getCouponBuzzlead();

const createAd = () => {
  if (window.location.pathname === rotaResultados.url) {
    return (isCartSidebarVisible.value = false);
  }

  return (document.location.href = rotaResultados.url);
};

const myOrders = () => {
  return (document.location.href = rotaAnuncios.url);
};

const lastOrders = async () => {
  const response = await getOrdersLimit();
  if (Array.isArray(response.orders) && response.orders.length > 0) {
    const firstOrder = response.orders[0];
    const firstKey = Object.keys(firstOrder.campaigns || {})[0];
    idOrder.value = firstOrder.id;
    lastCampaign.value = firstOrder.campaigns[firstKey];
  }
};

const handleCardClick = (orderId) => {
  document.location.href = `${rotaAnuncios.resumo.url}${orderId}`;
};

const getDate = (d) => {
  if (!d || !d.date_start || !d.duration) return { start: "N/A", end: "N/A" };
  let start = moment(d.date_start).format("DD/MM/YYYY");
  let end = moment(d.date_start)
    .add(d.duration - 1, "d")
    .format("DD/MM/YYYY");
  return { start, end };
};

const dispatchAddToCart = (data) => {
  CartStore.addToCart(data);
};

const dispatchRemoveItemFromCart = (id) => {
  CartStore.removeItemFromCart({ item_ids: [id] });
};

const dispatchRemoveAllItemsFromCart = () => {
  CartStore.removeAllItemsFromCart();
  dispatchFetchCart();
};

const handleCloseModal = (e) => (showDialogUpload.value = e);

const dispatchFetchCart = async () => {
  await CartStore.fetchCart();
};

const handleCleanCart = async () => {
  confirm.require({
    group: "headless",
    message: "Tem certeza de que deseja limpar o plano de mídia?",
    header: "Atenção",
    icon: "uil uil-trash",
    acceptLabel: "remover item",
    rejectLabel: "voltar",
    accept: () => {
      dispatchRemoveAllItemsFromCart();
    },
    reject: () => {},
  });
};

const handleRemoveItem = async (id) => {
  confirm.require({
    group: "headless",
    message: "Tem certeza de que deseja remover esse item do plano de mídia?",
    header: "Atenção",
    icon: "uil uil-trash",
    acceptLabel: "Sim",
    rejectLabel: "Não",
    accept: () => {
      dispatchRemoveItemFromCart(id);
      //dataLayer("remove_from_cart", [pointList.value.find((objeto) => objeto.id === id)]);
    },
    reject: () => {},
  });
};

const handleProgressiveDiscount = () => {
  if (progressiveDiscount.value) {
    progressiveDiscountData.value = progressiveDiscount.value;
    showCardProgressiveDiscount.value =
      progressiveDiscountData.value?.coupon?.code != coupon?.value?.data?.code &&
      progressiveDiscountData.value?.disableByUser;
    loadingProgressiveDiscount.value = false;
  }
};

const handleApplyProgressiveDiscount = async (couponCode) => {
  loadingProgressiveDiscount.value = true;
  try {
    await CartStore.updateCoupon({
      coupon: couponCode,
    });

    if (CartStore.coupon[0].is_valid) {
      toast.add({
        severity: "success",
        summary: "Cupom aplicado com sucesso!",
        life: 3000,
      });
    } else if (!CartStore.coupon[0].is_valid && couponCode != "") {
      toast.add({
        severity: "error",
        summary: "O cupom não é válido!",
        life: 3000,
      });
    }

    deeplink.clear();
  } catch (error) {
    console.error("Erro ao atualizar o cupom:", error);
  }
  handleProgressiveDiscount();
};

const redirectToCart = async () => {
  Tracker.getInstance().track(Tracker.EVENT_BUTTON_CONTINUAR, trackButton("Continuar para o resumo do plano de mídia"));
  document.location.href = rotaPlanoDeMidia.url;
  return;
};

const hasStaticCartItems = computed(() =>
  cartItems.value.some((item) => item.properties?.exhibition_type === AssetEnum.static),
);

const handleSidebarTopPosition = () => {
  const header = document.querySelector("header");
  if (!header || isMobile.value) return;

  const headerHeight = `${header.offsetHeight}px`;
  const sidebarHeight = `calc(100% - ${headerHeight})`;

  const sidebarMask = document.querySelector(
    ".p-sidebar-mask.p-component-overlay.p-component-overlay-enter.p-sidebar-visible.p-sidebar-right",
  );

  if (!sidebarMask) return;

  // sidebarMask.style.top = headerHeight;
  // sidebarMask.style.height = sidebarHeight;
  sidebarMask.classList.add("apply-zoom");
};

watch(cartItems, () => {
  pointList.value = new Proxy(cartItems.value, {});
});

watch(
  [
    cartItems,
    cartCount,
    subtotal,
    total,
    id,
    date_start,
    duration,
    coupon,
    progressiveDiscount,
    progressiveDiscountData,
    showCardProgressiveDiscount,
  ],
  () => {
    if (cartCount.value > 0) {
      campaignId.value = id.value;
      hideBuzzleadCoupon.value = CartStore.coupon[0]?.code == couponBuzzlead;

      campaignDates.start = moment(date_start.value).format("DD/MM/YYYY");
      campaignDates.end = moment(date_start.value).add(duration.value, "d").subtract(1, "d").format("DD/MM/YYYY");
      pointList.value = cartItems.value;
    } else if (cartCount.value === 0) {
      lastOrders();
    }
    disableNextButton.value = !cartCount.value > 0;
    handleProgressiveDiscount();
  },
);

watch([total, subtotal], () => {
  cartSubtotal.value = subtotal.value;
  cartTotal.value = total.value;
});

watch(cartItems, () => {
  pointList.value = new Proxy(cartItems.value, {});
});

watch(
  () => props.isCartSidebarVisible,
  async (newValue) => {
    await dispatchFetchCart();
    isCartSidebarVisible.value = newValue;
    setTimeout(() => {
      handleSidebarTopPosition();
    }, 0);
  },
);

onMounted(async () => {
  await dispatchFetchCart();

  cartSubtotal.value = subtotal.value;
  cartTotal.value = total.value;

  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get("from") === "register" && cartCount.value > 0) {
    showDialogUpload.value = true;
  }

  handleProgressiveDiscount();
});
</script>

<style lang="scss" scoped>
.showForms-enter-active,
.showForms-leave-active {
  transition: all 0.25s ease-out;
}

.showForms-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.showForms-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>

<style lang="scss">
.cart-sidebar {
  .p-sidebar-header {
    .p-sidebar-header-content {
      @apply text-3xl lg:text-6xl font-bold lg:px-16;
    }
    .p-sidebar-close {
      @apply lg:mr-40;
    }
  }
  .p-sidebar-content {
    @apply p-0;
  }
}

.cart-sidebar::-webkit-scrollbar {
  display: none;
}

.cart-sidebar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.p-button.without-border {
  border: none !important;
}
</style>
