<template>
  <div class="w-full">
    <div
      class="searchbar-header absolute left-1/2 z-10 mx-auto -translate-x-1/2 transform"
      :class="isMinimized ? 'top-9' : 'top-6'"
    >
      <div class="search group/search" :class="!isMinimized && '!h-[80px]'">
        <!-- placeholder -->
        <div
          class="search--placeholder group-hover/search:pointer-events-none group-hover/search:opacity-0"
          :class="!isMinimized && ' pointer-events-none opacity-0 '"
        >
          <div class="flex h-full w-[255px] items-center justify-start border-r border-[#EDF2F8]">
            <i class="uil-search mr-3 text-[#BCC3CD]"></i>
            <p class="truncate">
              {{ address?.endereco ?? "Pesquise um endereço ou região" }}
            </p>
          </div>
          <div class="flex h-full w-[165px] items-center border-r border-[#EDF2F8] px-4">
            {{ duration?.name ?? "duração" }}

            <i class="uil-angle-down ml-4 mr-5 text-[#282E38]"></i>
          </div>
          <div class="mr-7 flex h-full w-[94px] items-center px-4">
            {{ date ? convertDateToBr(date) : "inicio" }}

            <i class="uil-angle-down mr-5text-[#282E38] ml-4"></i>
          </div>
          <Button class="ml-7 w-[80px] shrink-0 justify-center" @click="dispatchSearch"> buscar </Button>
        </div>
        <form
          autocomplete="off"
          autofocus
          class="search--container opacity-0 transition-all group-hover/search:pointer-events-auto group-hover/search:opacity-100"
          :class="!isMinimized && ' pointer-events-auto opacity-100 '"
        >
          <label class="h-full w-full border-r border-[#EDF2F8]">
            <SearchAddress
              label="Endereço ou região"
              :input-focus="focus"
              :saved-address="address"
              appearance="integrated"
              @address-choosed="(a) => ResultsStore.setAddressFromComp(a)"
            />
          </label>
          <label class="border-[#EDF2F8 integrated-dropdown flex h-full max-w-[135px] flex-col border-r p-4">
            <CampaignDurationPicker v-model="duration" label="duração" class="form-field mt-3 w-full !text-lg" />
          </label>
          <label class="mr-4 flex h-full min-w-[107px] max-w-[107px] flex-col relative">
            <CampaignDatePicker
              v-model="date"
              :model-value="selectedDate"
              label="início"
              class="form-field form-field-wrapper ui-state-filled w-full"
              inputStyle=""
              :appearance="'integrated'"
              @update:model-value="(d) => ResultsStore.setDate(d)"
            />
            <i class="!text-2xl uil-angle-down absolute right-0 top-[29px]"></i>
          </label>

          <Button class="ml-auto mr-4 w-[81px] shrink-0 justify-center" @click="dispatchSearch"> buscar </Button>
        </form>
      </div>
    </div>

    <div v-auto-animate class="mb-4 flex w-full flex-col items-center justify-center px-[80px] text-lg">
      <div
        v-if="(headerHover && ResultsStore.ui.visibility.filterPreview) || keepHeaderOpen"
        class="mb-8 mt-7 flex w-full"
      >
        <div class="flex w-full justify-center gap-3">
          <Chip
            v-for="category in ResultsStore.categories"
            :key="category.icon"
            :label="category.name"
            class="cursor-pointer text-nowrap !px-4 !py-1 !text-lg"
          >
            <template #default>
              <span class="mr-1 lowercase text-neutral-dark4" @click="emit('open-filter')">{{ category.name }}</span>
              <span
                v-if="ResultsStore.categories.length > 1"
                :class="[ResultsStore.categories.length === 1 && 'pointer-events-none']"
                :title="`remover ${category.name}`"
                @click="handleCategoryChange(category)"
                ><i class="uil-times-circle text-lg text-surface-800"
              /></span>
            </template>
          </Chip>
          <!-- <Chip
            v-if="getCharacteristicsLength() > 0"
            class="cursor-pointer text-nowrap bg-primary-default !px-4 !py-1 !text-lg"
          >
            <template #default>
              <span class="mr-1 lowercase text-white" @click="emit('open-filter')">{{ advancedFiltersChipLabel }}</span>
              <span
                :title="`remover ${advancedFiltersChipLabel}`"
                class="!text-white"
                @click="handleRemoveAdvancedFilters()"
                ><i class="uil-times-circle text-lg text-white"
              /></span>
            </template>
          </Chip> -->
        </div>
      </div>
      <div
        v-if="(headerHover && ResultsStore.ui.visibility.filterBar) || keepHeaderOpen"
        class="flex w-full items-center border-t border-[#DFE7EF] pt-2"
      >
        <div class="h-full items-center relative">
          <FiltersAndSort
            :sort-label="'ordenar'"
            :sort-options="sort"
            :forced-position="false"
            @sort:click="keepHeaderOpen = true"
            @sort:change="sortFilter"
            @filter:toggle="toggleSidebar"
          />
        </div>
        <div class="ml-auto flex">
          <InputSwitch v-model="showMap" inputId="mapView" @change="toggleMapView" />
          <label v-auto-animate for="mapView" class="ml-3 cursor-pointer"
            >{{ !showMap ? "exibir" : "ocultar" }} mapa</label
          >
        </div>
        <!-- <div class="flex gap-4">
          <Button
            text
            class="without-border group/button !bg-transparent !text-[#282E38] hover:!text-[#FF4F00]"
            :class="currentViewMode === 'list' && '!text-[#FF4F00]'"
            :icon="[
              'transition-all  duration-200 uil-list-ul text-[#59282E38] group-hover/button:!text-[#FF4F00]',
              currentViewMode === 'list' && '!text-[#FF4F00]',
            ]"
            label="ver em lista"
            @click="toggleResultsViewMode('list')"
          >
          </Button>
          <Button
            text
            class="without-border group/button !bg-transparent !text-[#282E38] hover:!text-[#FF4F00]"
            :class="currentViewMode === 'grid' && '!text-[#FF4F00]'"
            :icon="[
              'transition-all  duration-200 uil-table text-[#59282E38] group-hover/button:!text-[#FF4F00]',
              currentViewMode === 'grid' && '!text-[#FF4F00]',
            ]"
            label="ver em grade "
            @click="toggleResultsViewMode('grid')"
          >
          </Button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import CampaignDatePicker from "@components/pages/SearchLocation/components/CampaignDatePicker.vue";
import CampaignDurationPicker from "@components/pages/SearchLocation/components/CampaignDurationPicker.vue";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, toRef, watch } from "vue";

import { CampaignDurationAsList } from "@/enums/OrderEnum";
import { rotaResultados } from "@/routes";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useCartStore } from "@/stores/CartStore";
import { useSearchProductsStore } from "@/stores/SearchProductsStore";
import { useUIStore } from "@/stores/UIStore";
import { convertDateToBr } from "@/utils/dateparser/dateHelper";
import dateParser, { isValidDate } from "@/utils/dateparser/dateparser";

import FiltersAndSort from "../Filter/FiltersAndSort.vue";
import { sortOptionFactory } from "../Filter/sortOptionFactory";
import InputBox from "../InputBox.vue";
import SearchAddress from "../pages/SearchLocation/components/SearchAddress.vue";

const props = defineProps({
  headerHover: {
    type: Boolean,
    default: false,
  },
});

const CartStore = useCartStore();
const CampaignStore = useCampaignStore();
const UIStore = useUIStore();
const ResultsStore = useSearchProductsStore();

const isMinimized = ref(false);

const showMap = toRef(ResultsStore.showMap);
const currentViewMode = ref("");
const keepHeaderOpen = ref(false);

const address = ref();
const duration = ref();
const date = ref();

const selectedDate = computed(() => {
  const date = dateParser(ResultsStore.start);
  if (isValidDate(date)) {
    return date;
  }

  return "";
});

const sort = ref([
  sortOptionFactory("$ menor para maior", undefined, "", () => ResultsStore.toggleSort("price|asc")),
  sortOptionFactory("$ maior para menor", undefined, "", () => ResultsStore.toggleSort("price|desc")),
  sortOptionFactory("distância", undefined, "", () => ResultsStore.toggleSort("distance")),
  sortOptionFactory("alfabética", undefined, "", () => ResultsStore.toggleSort("name")),
]);

const dispatchSearch = async () => {
  await ResultsStore.setDuration(duration.value);
  if (ResultsStore.isResultPage()) {
    ResultsStore.fetchProductsBoth();
    return;
  }
  window.location.href = rotaResultados.url;
};

const handleCategoryChange = (cat) => {
  const cats = [...(ResultsStore.search.verticals ?? [])];
  const code = cat.code;
  const catIndex = cats.findIndex((c) => c.code === code);
  const isSelected = catIndex !== -1;

  if (isSelected) {
    if (cats.length > 1) {
      cats.splice(catIndex, 1);
    }
  } else {
    cats.push(cat);
  }

  ResultsStore.setVerticals(cats);
  ResultsStore.fetchProductsFull();
};

const handleScroll = () => {
  isMinimized.value = window.scrollY > 0;
};

const toggleSidebar = () => {
  UIStore.toggleSidebar("filter");
};

const toggleMapView = () => {
  ResultsStore.toggleMapView();
};

const toggleResultsViewMode = (view) => {
  ResultsStore.toggleResultsViewMode(view);
  currentViewMode.value = ResultsStore.viewMode;
};

watch(
  () => ResultsStore.search,
  (newSearch) => {
    address.value = {
      endereco: newSearch.address.name,
      enderecoId: newSearch.address.id,
    };
    date.value = newSearch.start;
    duration.value = newSearch.duration;
  },
  { deep: true, immediate: true },
);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  currentViewMode.value = ResultsStore.viewMode;
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.p-button.without-border {
  border: none !important;
}
.search {
  @apply z-1 w-[658px] flex flex-col h-[58px] items-center rounded-xl border border-[#EDF2F8] bg-[#FBFDFF] px-7 py-3 text-lg transition-all hover:h-[80px] hover:border-[#FF9C70];
  box-shadow: 0px 4px 12px 0px #37404c26;
  &--placeholder {
    @apply absolute top-0 flex w-full h-full items-center transition-all px-7;
  }
  &--container {
    @apply absolute top-0 flex h-full w-full items-center transition-all p-[8px];
  }
}
:deep(.integrated-dropdown) {
  @apply p-4 bg-white text-[#64748b];
  transition: all 0.3s ease-in-out;
  &:focus,
  &:focus-within {
    @apply bg-[#f3f6fb];
  }
  label {
    @apply text-[#64748b] mb-[0] lowercase leading-[17px];
  }
  label {
    margin-bottom: 6px;
    text-transform: lowercase;
    line-height: 17px;
  }
  .form-field {
    @apply bg-transparent  mt-0 rounded-none border-transparent bg-white h-5 p-0 text-[#282E38] leading-[17px];
  }
  .p-dropdown {
    @apply rounded-none border-transparent bg-white h-5 p-0 text-[#282E38] leading-[17px];

    transition: all 0.3s ease-in-out;
    .p-dropdown-label.p-inputtext {
      padding: 0 !important;
      line-height: 17px;
      height: 1.25rem;
      color: #282e38;
      border-radius: 0 !important;
    }

    &:hover {
      @apply border-transparent;
    }
    &:focus,
    &:focus-within {
      @apply bg-[#f3f6fb] border-transparent shadow-none;
    }
  }
}
</style>
