<template>
  <Sidebar
    v-model:visible="isMenuSidebarVisible"
    modal
    class="menu-sidebar"
    :position="'right'"
    :class="isMobile ? 'w-full' : 'w-2/6'"
    :blockScroll="true"
    @hide="handleClose"
  >
    <template v-if="!isLogged()" #header>
      <img loading="lazy" :src="props.logo" :alt="'Logotipo Eletromidia Aqui'" :width="100" class="max-w-[50px]" />
    </template>
    <template v-else #header>
      <div class="flex justify-center gap-2">
        <UserAvatar :photo="props.photo" />
        <p class="font-bold text-xl">
          {{ message }}
        </p>
      </div>
    </template>
    <template #default>
      <div class="flex h-full w-full flex-col">
        <Menu :model="props.menuItems" class="!border-none !w-full">
          <template #submenuheader="{ item }">
            <div class="flex w-full items-center">
              <span :class="item.icon" class />
              <span class="text-primary font-bold">{{ item.label }}</span>
              <span :class="item.iconEnd" class="ml-auto" />
            </div>
          </template>

          <template #item="{ item, props }">
            <a
              :href="item.url"
              :target="item.target"
              class="flex !w-full hover:bg-neutral-light7"
              v-bind="props.action"
              @click="item.handleFunction"
            >
              <span v-if="item.icon" :class="item.icon" class="mr-2" />
              <span class="whitespace-nowrap !p-0">{{ item.label }}</span>
              <span :class="item.iconEnd" class="ml-auto" />
            </a>
          </template>
        </Menu>
      </div>
    </template>
  </Sidebar>
</template>

<script setup>
import Cookies from "js-cookie";
import { computed, onMounted, ref, watch } from "vue";

import UserAvatar from "@/components/UserAvatar.vue";
import useIsMobile from "@/hooks/useIsMobile";
import {
  rotaAnuncios,
  rotaBibliotecaMidias,
  rotaCadastro,
  rotaColaboradores,
  rotaContas,
  rotaIdentificacao,
  rotaPerfil,
  rotaResultados,
} from "@/routes";
import { useUserStore } from "@/stores/UserStore";
const { isMobile } = useIsMobile();

const props = defineProps({
  menuItems: {
    type: Array,
    required: true,
  },
  logo: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    default: "Olá!",
  },
  photo: {
    type: String,
    required: true,
  },
  isMenuSidebarVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);

const isMenuSidebarVisible = ref(props.isMenuSidebarVisible);
const UserStore = useUserStore();

const isLogged = () => {
  return UserStore.token && UserStore.email;
};

const handleSidebarTopPosition = () => {
  const header = document.querySelector("header");
  if (!header || isMobile) return;

  const headerHeight = `${header.offsetHeight}px`;
  const sidebarHeight = `calc(100% - ${headerHeight})`;

  const sidebarMask = document.querySelector(
    ".p-sidebar-mask.p-component-overlay.p-component-overlay-enter.p-sidebar-visible.p-sidebar-right",
  );

  if (!sidebarMask) return;

  // sidebarMask.style.top = headerHeight;
  // sidebarMask.style.height = sidebarHeight;
  sidebarMask.classList.add("apply-zoom");
};

const handleClose = () => {
  emit("close");
};

watch(
  () => props.isMenuSidebarVisible,
  (newValue) => {
    isMenuSidebarVisible.value = newValue;
    setTimeout(() => {
      handleSidebarTopPosition();
    }, 0);
  },
);

onMounted(() => {});
</script>
<style lang="scss">
.menu-sidebar {
  .p-sidebar-header {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .p-sidebar-header-content {
    @apply text-3xl font-bold;
  }
}
</style>
